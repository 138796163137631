import React from "react";
import { useNavigate} from "react-router-dom";

// Import axios for making HTTP requests
import axios from "axios";

// Simple popup component after sending email
const Emailsent = (props) => {
    const data = JSON.parse(localStorage.getItem("data"));
    const applicant_id = data ? data.applicant_id : null;
    const navigate = useNavigate();
  // Function to handle API call when "No" button is clicked
  const handleNoButtonClick = () => {
    // Make a POST request to cancel the document generation
    axios.post(`https://api.xhorizons-uat.lexxtechnologies.com/cancelgen/${applicant_id}`)
      .then(response => {
        // Handle success response
        console.log("Document generation cancelled successfully:", response.data);
        navigate("/");
      })
      .catch(error => {
        // Handle error
        console.error("Error cancelling document generation:", error);
      });
  };
  const handleYesButtonClick = () => {
    // Make a POST request to cancel the document generation
    axios.post(`https://api.xhorizons-uat.lexxtechnologies.com/sendemp/${applicant_id}`)
      .then(response => {
        // Handle success response
        console.log("Document generation cancelled successfully:", response.data);
        navigate("/emailsent");
      })
      .catch(error => {
        // Handle error
        console.error("Error cancelling document generation:", error);
      });
  };

  return (
    <div className="docemailpopup">
      <div className="docsentpop">
        <div className="docmail">
          <h1>
            All the documents are successfully generated.
            <h1 className="h1-text">Do you want to send them in an Email to the Applicant?</h1>
          </h1>
        </div>
        <div className="alert-message-button">
          {/* Call handleNoButtonClick function on click of "No" button */}
          <button className="okay-popup" onClick={handleNoButtonClick}>No</button>
            <button className="okay-popup" onClick={handleYesButtonClick}>Yes</button>
        </div>
      </div>
    </div>
  );
};

export default Emailsent;