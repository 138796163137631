import React from 'react';
import { useLocation } from 'react-router-dom';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

const PdfViewer = () => {
  const newplugin = defaultLayoutPlugin();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const fileUrl = decodeURIComponent(params.get("url"));

  const onError = (error) => {
    let errorMessage = "Unknown error";
    if (error && error.message) {
      errorMessage = error.message;
    }
    console.log(errorMessage, "error in file-viewer");
  };

  return (
    <>
      <div className='pdf-viwer'>
        <div className="emp-result-viewer">
          <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js'>
            <Viewer fileUrl={fileUrl} plugins={[newplugin]} onError={onError} />
          </Worker>
        </div>
      </div>
      <div className="button-previous">
        <Link to={"/generate"}>
          <Button className="button-1">Back</Button>
        </Link>
      </div>
    </>
  );
};

export default PdfViewer;
