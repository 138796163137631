/*******************************************************************************
* Contributors: Abhishek Kuamr Yadav
*******************************************************************************/
import React from "react";
import LexxDashboardCard from "./components/Dashboard/LexxDashboard";
import Header from "./components/LexxHeader/Header";
import { Routes, Route } from "react-router-dom";
import KeycloakUserService from "./components/login/KeyCloakService";
import { StatusProvider } from "./statusContext";
import FileUpload from "./components/file/FileUpload";
import SubmitResult from "./components/result/SubmitResult";
import Emailsent from "./components/Checklist/Emailsent";
import CheckList from "./components/Checklist/ApplicationType";
import FormFiled from "./components/Form/FormField";
import FormFiled2 from "./components/Form/FormField2";
import EmploymentReferenceForm from "./components/Generate/GenerateField"
import Previwer from "./components/Generate/Previwer";
import AlertSend from "./components/Generate/AlertSend";
import ValidationField from "./components/Collect/ValidationPage";


function App() {
  //for keycloack login
  KeycloakUserService.getUsername();
  return (
    <StatusProvider>
      <div>
        <Header />
        <Routes>
          {/* home page route  */}
          <Route exact path="/" element={<LexxDashboardCard />}></Route>
          {/* upload the file  */}
          <Route exact path="/uploadfile" element={<FileUpload />}></Route>
          {/* submit the result after upload */}
          <Route exact path="/submitResult" element={<SubmitResult />}></Route>
          {/* email sent popup message */}
          <Route exact path="/emailsent" element={<Emailsent />}></Route>
          {/* create a checklist */}
          <Route exact path="/checklist" element={<CheckList />}></Route>
          <Route exact path="/formfiled" element={<FormFiled />}></Route>
          <Route exact path="/formfiledapplicent" element={<FormFiled2 />}></Route>
          <Route exact path="/generate" element={<EmploymentReferenceForm />}></Route>
          <Route exact path="/viewer" element={<Previwer />}></Route>
          <Route exact path="/alertmessage" element={<AlertSend />}></Route>
          <Route exact path="/validationpage" element={<ValidationField />}></Route>
        </Routes>
      </div>
    </StatusProvider>
  );
}

export default App;
