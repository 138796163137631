// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gen-field{
  margin-top: 100px;
  display: grid;
   justify-content: space-around;
  
}
.gen-header{
  display: inline;
  margin: auto;
  width: 100%;
  /* border: 3px solid green; */
  padding: 10px;
  text-align: center;
  background-color: #0971ce;
  color:white;
}
textarea{
  width: 20vw;
}
.dropdown{
  width: 20vw;
  height: 30px;
  text-align: center;
}
.button-ref{
  display: flex;
  margin: auto;
  width: 150px;
  height: 40px;
  background-color: #0971ce;
  border-radius: 50px;
  color: white !important;
  padding-left: 50px;
  
}
.ref-colo{
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Generate/gen.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;GACZ,6BAA6B;;AAEhC;AACA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,6BAA6B;EAC7B,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;;AAEpB;AACA;EACE,YAAY;AACd","sourcesContent":[".gen-field{\n  margin-top: 100px;\n  display: grid;\n   justify-content: space-around;\n  \n}\n.gen-header{\n  display: inline;\n  margin: auto;\n  width: 100%;\n  /* border: 3px solid green; */\n  padding: 10px;\n  text-align: center;\n  background-color: #0971ce;\n  color:white;\n}\ntextarea{\n  width: 20vw;\n}\n.dropdown{\n  width: 20vw;\n  height: 30px;\n  text-align: center;\n}\n.button-ref{\n  display: flex;\n  margin: auto;\n  width: 150px;\n  height: 40px;\n  background-color: #0971ce;\n  border-radius: 50px;\n  color: white !important;\n  padding-left: 50px;\n  \n}\n.ref-colo{\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
