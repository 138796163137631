/*******************************************************************************
 * Keycloack: This components used for the login purpose of user using keycloak
 * Contributors: Abhishek Kuamr Yadav
 *******************************************************************************/
import Keycloak from "keycloak-js";

const keycloakJson = {
  realm: process.env.REACT_APP_REALM,
  url: `${process.env.REACT_APP_KEYCLOAK_HOST}/auth/`,
  "ssl-required": process.env.REACT_APP_KEYCLOAK_SSL_REQUIRED,
  clientId: process.env.REACT_APP_KEYCLOAK_RESOURCE,
  // "resource": process.env.REACT_APP_KEYCLOAK_RESOURCE,
  "public-client": process.env.REACT_APP_KEYCLOAK_PUBLIC_CLIENT,
  "confidential-port": process.env.REACT_APP_KEYCLOAK_CONFIDENTIAL_PORT,
};

const _kc = new Keycloak(keycloakJson);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: "login-required",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256",
    })
    .then((authenticated) => {
      onAuthenticatedCallback();
    })
    .catch(console.error);
};
const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const getEmail = () => _kc.idTokenParsed.email;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const getRoles = () => _kc.tokenParsed?.roles;

const getUserID = () => _kc.tokenParsed?.sub;

const isAuthenticated = () => _kc.authenticated;

const getGroups = () => _kc.tokenParsed?.groups;

const KeycloakUserService = {
  initKeycloak,
  doLogin,
  doLogout,
  getEmail,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getRoles,
  getUserID,
  isAuthenticated,
  getGroups,
};

export default KeycloakUserService;
