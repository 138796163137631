// Sidebar.js
import React from "react";
import { Link } from "react-router-dom";
import home from "../../images/home.png";
import team from "../../images/team.png";
import person from "../../images/person.png";
import setting from "../../images/setting.png";

const Sidebar = (props) => {
  const closeSidebar = () => {
    props.setShow(false);
  };

  return (
    <div className={`sidebarflex ${props.show ? "" : "sidebarhidden"}`}>
      <div className="sidebar">
        <Link to={"/"} onClick={closeSidebar}>
          <div className="sidebaritems">
            <img width={"20px"} src={home} alt="cross icon" />
            <div style={{ marginLeft: "10px" }}>Dashboard</div>
          </div>
        </Link>
        <div className="sidebaritems">
          <img width={"20px"} src={person} alt="cross icon" />
          <div style={{ marginLeft: "10px" }}>My Profile</div>
        </div>
        <div className="sidebaritems">
          <img width={"20px"} src={team} alt="cross icon" />
          <div style={{ marginLeft: "10px" }}>My Team</div>
        </div>
        <div className="sidebaritems">
          <img width={"20px"} src={setting} alt="cross icon" />
          <div style={{ marginLeft: "10px" }}>Settings</div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
