// PopupMessage.jsx
import React from "react";

const PopupMessage = ({ message, onClose }) => {
  return (
    <div className={`popup-container${message ? ' active' : ''}`}>
      {message && (
        <div className="popup">
          <p className="messaage-text-alert-win">{message}</p>
          <div className="close-pop-bt">
          <button className="popSubmitbt" onClick={onClose}>Close</button>
          </div>       
           </div>
      )}
    </div>
  );
};

export default PopupMessage;
