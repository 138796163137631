/***************************
 * Skills: This components show the datalist of recomanded pathway Skills
 * Contributors: Abhishek Kuamr Yadav
 ***************************/

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import "./../../css/formField.css";
// Employment Detail table data
const FormFiled = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [personaldetail, setPersonalDetail] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    DateOfBirth: "",
    Nationality: "",
  });
  useEffect(() => {
    async function fetchPersonalDetails() {
      const url = `https://api.xhorizons-uat.lexxtechnologies.com/getdata/${location.state.applicant_id}`;
      const response = await fetch(url);
      const data = await response.json();
      setPersonalDetail({
        FirstName: data.FirstName,
        LastName: data.LastName,
        Email: data.Email,
        DateOfBirth: data.DateOfBirth,
        Nationality: data.Nationality,
      });
    }

    fetchPersonalDetails();
  }, []);

  const onchangePersonalDate = (e) => {
    setPersonalDetail({ ...personaldetail, [e.target.name]: e.target.value });
  };
  const onchangePersonalData = (e) => {
    const { name, value } = e.target;
    const alphaRegex = /^[a-zA-Z]*$/; // Regular expression to match only alphabetic characters
    if (!alphaRegex.test(value)) {
      // If the input value contains non-alphabetic characters, do not update the state
      return;
    }
    setPersonalDetail({
      ...personaldetail,
      [name]: value.trim().replace(/\b\w/g, (c) => c.toUpperCase()),
    });
  };

  // educational qualification data
  const [educationalData, setEducationalData] = useState({
    // Level_of_Qualification: "",
    Name_of_Qualification: "",
    Name_of_Institute: "",
    Country_of_Awarding: "",
    Year_of_Commencement: "",
    Year_of_Completion: "",
    Part_Full_Time: "",
  });
  // educational qualification table data
  const [educationalDataTable, setEducationalDataTable] = useState({
    data: [],
  });

  // educational qualification current data updating function
  const onChangeEducationalDate = (e) => {
    setEducationalData({ ...educationalData, [e.target.name]: e.target.value });
  };
  const onChangeEducationalData = (e) => {
    const { name, value } = e.target;
    // Only allow alphabets and spaces
    const regex = /^[a-zA-Z\s]*$/;
    if (regex.test(value) || value === "") {
      setEducationalData({ ...educationalData, [name]: value });
    }
  };

  // educational qualification table data updating function
  const addEducationalData = () => {
    if (
      educationalData.Country_of_Awarding === "" ||
      educationalData.Name_of_Institute === "" ||
      educationalData.Name_of_Qualification === "" ||
      educationalData.Part_Full_Time === "" ||
      educationalData.Year_of_Commencement === "" ||
      educationalData.Year_of_Completion === ""
    ) {
      alert(
        "Please fill all the required fields of Educational Qualifications to add your Qualifications"
      );
    } else if (educationalData.Year_of_Completion < educationalData.Year_of_Commencement) {
      alert("Year of Completion cannot be earlier than Year of Commencement");
    } else {
      setEducationalDataTable({
        data: educationalDataTable.data.concat(educationalData),
      });
      setEducationalData({
        Name_of_Qualification: "",
        Name_of_Institute: "",
        Country_of_Awarding: "",
        Year_of_Commencement: "",
        Year_of_Completion: "",
        Part_Full_Time: "",
      });
      if (document.querySelector('input[name="Part_Full_Time"]:checked')) {
        document.querySelector(
          'input[name="Part_Full_Time"]:checked'
        ).checked = false;
      }
    }
  };

  // Employment Detail data
  const [employemntData, setEmployemntData] = useState({
    // Number_of_Employments: "",
    Name_of_Company: "",
    Destination: "",
    Part_Full_Time_Employment: "",
    Year_of_Commencement: "",
    Year_of_Completion: "",
    Currently_Working: "",
    Job_Responsibility: "",
  });
  // Employment Detail table data
  const [employemntDataTable, setEmployemntDataTable] = useState({ data: [] });

  // Employment Detail current data updating function
  const OnchangeEmployemntDate = (e) => {
    if (e.target.name === "Currently_Working") {
      if (document.querySelector('input[name="Currently_Working"]:checked')) {
        setEmployemntData({
          ...employemntData,
          Currently_Working: "Currently_Working",
        });
      } else {
        setEmployemntData({ ...employemntData, Currently_Working: "" });
      }
    } else {
      setEmployemntData({ ...employemntData, [e.target.name]: e.target.value });
    }
  };
  const OnchangeEmployemntData = (e) => {
    if (e.target.name === "Currently_Working") {
      if (document.querySelector('input[name="Currently_Working"]:checked')) {
        setEmployemntData({
          ...employemntData,
          Currently_Working: "Currently_Working",
        });
      } else {
        setEmployemntData({ ...employemntData, Currently_Working: "" });
      }
    } else {
      let value = e.target.value;
      // Only allow alphabetic characters or empty string
      if (/^[a-zA-Z]*$/.test(value) || value === "") {
        setEmployemntData({ ...employemntData, [e.target.name]: value });
      }
    }
  };

  // Employment Detail table data updating function
  const addEmployemntData = () => {
    if (
      employemntData.Destination === "" ||
      employemntData.Job_Responsibility === "" ||
      employemntData.Name_of_Company === "" ||
      employemntData.Part_Full_Time_Employment === "" ||
      employemntData.Year_of_Commencement === ""
    ) {
      alert("Please fill all the required fields of Employment Details to add");
    } else if (employemntData.Currently_Working !== "Currently_Working" && employemntData.Year_of_Completion < employemntData.Year_of_Commencement) {
      alert("Year of Completion cannot be earlier than Year of Commencement");
    } else {
      setEmployemntDataTable({
        data: employemntDataTable.data.concat(employemntData),
      });
      setEmployemntData({
        Name_of_Company: "",
        Destination: "",
        Part_Full_Time_Employment: "",
        Year_of_Commencement: "",
        Year_of_Completion: "",
        Currently_Working: "",
        Job_Responsibility: "",
      });
      if (
        document.querySelector('input[name="Part_Full_Time_Employment"]:checked')
      ) {
        document.querySelector(
          'input[name="Part_Full_Time_Employment"]:checked'
        ).checked = false;
      }
      if (document.querySelector('input[name="Currently_Working"]:checked')) {
        document.querySelector(
          'input[name="Currently_Working"]:checked'
        ).checked = false;
      }
    }
  };

  const submitFormData = async () => {
    if (
      educationalDataTable.data.length === 0 &&
      employemntDataTable.data.length === 0
    ) {
      alert(
        "Please add at least one educational qualification or employment detail"
      );
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(personaldetail.Email)) {
      alert("Provided email is not valid");
      return;
    } else if (
      personaldetail.DateOfBirth === "" ||
      personaldetail.Email === "" ||
      personaldetail.FirstName === "" ||
      personaldetail.LastName === "" ||
      personaldetail.Nationality === ""
    ) {
      alert("Please fill all the required fields");
      return;
    } else if (educationalDataTable.data.length === 0) {
      alert("Please add at least one educational qualification");
      return;
    } else if (employemntDataTable.data.length === 0) {
      alert("Please add at least one employment detail");
      return;
    }

    let data = {
      Qualifications: educationalDataTable.data.map((item) => {
        return {
          QualificationDescription: item.Name_of_Qualification,
          InstitutionName: item.Name_of_Institute,
          QualificationType: item.Part_Full_Time,
          QualificationStartDate: item.Year_of_Commencement,
          QualificationEndDate: item.Year_of_Completion,
          AwardingCountry: item.Country_of_Awarding,
        };
      }),
      Employments: employemntDataTable.data.map((item) => {
        return {
          Designation: item.Destination,
          EmploymentType: item.Part_Full_Time_Employment,
          EmploymentStartDate: item.Year_of_Commencement,
          EmploymentEndDate: item.Year_of_Completion,
          JobDescription: item.Name_of_Company,
          CurrentlyWorking: item.Currently_Working,
        };
      }),
    };

    console.log(data);
    const url = `https://api.xhorizons-uat.lexxtechnologies.com/insert/${location.state.applicant_id}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const json = await response.json();

    // Use React Router's useNavigate hook to navigate
    navigate("/submitResult");
  };

  return (
    <div className="form_body">
      <div className="h-context">
        <h3 className="pt-5 form_heading">APPLICANT ENTRY FORM</h3>
        <ul>
            <li style={{ color: "red" }}>
            Please note that fields marked with an asterisk (*) are mandatory.
            </li>
          </ul>
      </div>
      <form className="pb-10">
        <div>
          <h3 className="heading-form personal_details mb-5">
            PERSONAL DETAILS
          </h3>
          <div className="new-area">
            <div className="new-input personal_details_inputfields">
              <label>
                First Name{" "}
                <label style={{ color: "red", fontSize: "13px" }}>*</label>
              </label>
              <input
                onChange={onchangePersonalData}
                value={personaldetail.FirstName}
                type="text"
                name="FirstName"
                className="input-filed"
              />
            </div>
            <div className="new-input personal_details_inputfields">
              <label>
                Last Name{" "}
                <label style={{ color: "red", fontSize: "13px" }}>*</label>
              </label>
              <input
                onChange={onchangePersonalData}
                value={personaldetail.LastName}
                type="text"
                name="LastName"
                className="input-filed"
              />
            </div>
            <div className="new-input personal_details_inputfields">
              <label>
                {" "}
                Date of Birth{" "}
                <label style={{ color: "red", fontSize: "13px" }}>*</label>
              </label>
              <input
                onChange={onchangePersonalDate}
                value={personaldetail.DateOfBirth}
                name="DateOfBirth"
                type="date"
                className="input-filed"
              ></input>
            </div>
            <div className="new-input personal_details_inputfields">
              <label>
                {" "}
                Nationality{" "}
                <label style={{ color: "red", fontSize: "13px" }}>*</label>
              </label>
              <input
                onChange={onchangePersonalData}
                value={personaldetail.Nationality}
                type="text"
                name="Nationality"
                className="input-filed"
              />
            </div>
            <div className="new-input personal_details_inputfields">
              <label>
                {" "}
                Email ID{" "}
                <label style={{ color: "red", fontSize: "13px" }}>*</label>
              </label>
              <input
                onChange={onchangePersonalDate}
                value={personaldetail.Email}
                readOnly={personaldetail.Email !== ""}
                type="email"
                name="Email"
                className="input-filed"
              />
            </div>
          </div>
          <div className="form-filed-area-1"></div>
        </div>
        <div className="mt-5 mb-5">
          <h3 className="heading-form  educational_qualification mb-5">
            Educational Qualification
          </h3>

          <div className="new-area">
            <div className="new-input personal_details_inputfields">
              <label>
                Name of Qualification{" "}
                <label style={{ color: "red", fontSize: "13px" }}>*</label>
              </label>
              <input
                type="text"
                onChange={onChangeEducationalData}
                name="Name_of_Qualification"
                value={educationalData.Name_of_Qualification}
              />
            </div>
            <div className="new-input personal_details_inputfields">
              <label>
                {" "}
                Name of Institution{" "}
                <label style={{ color: "red", fontSize: "13px" }}>*</label>
              </label>
              <input
                onChange={onChangeEducationalData}
                value={educationalData.Name_of_Institute}
                type="text"
                name="Name_of_Institute"
                className="input-filed"
              />
            </div>
            <div className="new-input personal_details_inputfields">
              <label>
                Country of Awarding{" "}
                <label style={{ color: "red", fontSize: "13px" }}>*</label>
              </label>
              <select
                onChange={onChangeEducationalData}
                value={educationalData.Country_of_Awarding}
                class="form-select"
                id="country"
                name="Country_of_Awarding"
              >
                <option>Select Country </option>
                <option value="AF">Afghanistan</option>
                <option value="AX">Aland Islands</option>
                <option value="AL">Albania</option>
                <option value="DZ">Algeria</option>
                <option value="AS">American Samoa</option>
                <option value="AD">Andorra</option>
                <option value="AO">Angola</option>
                <option value="AI">Anguilla</option>
                <option value="AQ">Antarctica</option>
                <option value="AG">Antigua and Barbuda</option>
                <option value="AR">Argentina</option>
                <option value="AM">Armenia</option>
                <option value="AW">Aruba</option>
                <option value="AU">Australia</option>
                <option value="AT">Austria</option>
                <option value="AZ">Azerbaijan</option>
                <option value="BS">Bahamas</option>
                <option value="BH">Bahrain</option>
                <option value="BD">Bangladesh</option>
                <option value="BB">Barbados</option>
                <option value="BY">Belarus</option>
                <option value="BE">Belgium</option>
                <option value="BZ">Belize</option>
                <option value="BJ">Benin</option>
                <option value="BM">Bermuda</option>
                <option value="BT">Bhutan</option>
                <option value="BO">Bolivia</option>
                <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                <option value="BA">Bosnia and Herzegovina</option>
                <option value="BW">Botswana</option>
                <option value="BV">Bouvet Island</option>
                <option value="BR">Brazil</option>
                <option value="IO">British Indian Ocean Territory</option>
                <option value="BN">Brunei Darussalam</option>
                <option value="BG">Bulgaria</option>
                <option value="BF">Burkina Faso</option>
                <option value="BI">Burundi</option>
                <option value="KH">Cambodia</option>
                <option value="CM">Cameroon</option>
                <option value="CA">Canada</option>
                <option value="CV">Cape Verde</option>
                <option value="KY">Cayman Islands</option>
                <option value="CF">Central African Republic</option>
                <option value="TD">Chad</option>
                <option value="CL">Chile</option>
                <option value="CN">China</option>
                <option value="CX">Christmas Island</option>
                <option value="CC">Cocos (Keeling) Islands</option>
                <option value="CO">Colombia</option>
                <option value="KM">Comoros</option>
                <option value="CG">Congo</option>
                <option value="CD">
                  Congo, Democratic Republic of the Congo
                </option>
                <option value="CK">Cook Islands</option>
                <option value="CR">Costa Rica</option>
                <option value="CI">Cote D'Ivoire</option>
                <option value="HR">Croatia</option>
                <option value="CU">Cuba</option>
                <option value="CW">Curacao</option>
                <option value="CY">Cyprus</option>
                <option value="CZ">Czech Republic</option>
                <option value="DK">Denmark</option>
                <option value="DJ">Djibouti</option>
                <option value="DM">Dominica</option>
                <option value="DO">Dominican Republic</option>
                <option value="EC">Ecuador</option>
                <option value="EG">Egypt</option>
                <option value="SV">El Salvador</option>
                <option value="GQ">Equatorial Guinea</option>
                <option value="ER">Eritrea</option>
                <option value="EE">Estonia</option>
                <option value="ET">Ethiopia</option>
                <option value="FK">Falkland Islands (Malvinas)</option>
                <option value="FO">Faroe Islands</option>
                <option value="FJ">Fiji</option>
                <option value="FI">Finland</option>
                <option value="FR">France</option>
                <option value="GF">French Guiana</option>
                <option value="PF">French Polynesia</option>
                <option value="TF">French Southern Territories</option>
                <option value="GA">Gabon</option>
                <option value="GM">Gambia</option>
                <option value="GE">Georgia</option>
                <option value="DE">Germany</option>
                <option value="GH">Ghana</option>
                <option value="GI">Gibraltar</option>
                <option value="GR">Greece</option>
                <option value="GL">Greenland</option>
                <option value="GD">Grenada</option>
                <option value="GP">Guadeloupe</option>
                <option value="GU">Guam</option>
                <option value="GT">Guatemala</option>
                <option value="GG">Guernsey</option>
                <option value="GN">Guinea</option>
                <option value="GW">Guinea-Bissau</option>
                <option value="GY">Guyana</option>
                <option value="HT">Haiti</option>
                <option value="HM">Heard Island and Mcdonald Islands</option>
                <option value="VA">Holy See (Vatican City State)</option>
                <option value="HN">Honduras</option>
                <option value="HK">Hong Kong</option>
                <option value="HU">Hungary</option>
                <option value="IS">Iceland</option>
                <option value="IN">India</option>
                <option value="ID">Indonesia</option>
                <option value="IR">Iran, Islamic Republic of</option>
                <option value="IQ">Iraq</option>
                <option value="IE">Ireland</option>
                <option value="IM">Isle of Man</option>
                <option value="IL">Israel</option>
                <option value="IT">Italy</option>
                <option value="JM">Jamaica</option>
                <option value="JP">Japan</option>
                <option value="JE">Jersey</option>
                <option value="JO">Jordan</option>
                <option value="KZ">Kazakhstan</option>
                <option value="KE">Kenya</option>
                <option value="KI">Kiribati</option>
                <option value="KP">
                  Korea, Democratic People's Republic of
                </option>
                <option value="KR">Korea, Republic of</option>
                <option value="XK">Kosovo</option>
                <option value="KW">Kuwait</option>
                <option value="KG">Kyrgyzstan</option>
                <option value="LA">Lao People's Democratic Republic</option>
                <option value="LV">Latvia</option>
                <option value="LB">Lebanon</option>
                <option value="LS">Lesotho</option>
                <option value="LR">Liberia</option>
                <option value="LY">Libyan Arab Jamahiriya</option>
                <option value="LI">Liechtenstein</option>
                <option value="LT">Lithuania</option>
                <option value="LU">Luxembourg</option>
                <option value="MO">Macao</option>
                <option value="MK">
                  Macedonia, the Former Yugoslav Republic of
                </option>
                <option value="MG">Madagascar</option>
                <option value="MW">Malawi</option>
                <option value="MY">Malaysia</option>
                <option value="MV">Maldives</option>
                <option value="ML">Mali</option>
                <option value="MT">Malta</option>
                <option value="MH">Marshall Islands</option>
                <option value="MQ">Martinique</option>
                <option value="MR">Mauritania</option>
                <option value="MU">Mauritius</option>
                <option value="YT">Mayotte</option>
                <option value="MX">Mexico</option>
                <option value="FM">Micronesia, Federated States of</option>
                <option value="MD">Moldova, Republic of</option>
                <option value="MC">Monaco</option>
                <option value="MN">Mongolia</option>
                <option value="ME">Montenegro</option>
                <option value="MS">Montserrat</option>
                <option value="MA">Morocco</option>
                <option value="MZ">Mozambique</option>
                <option value="MM">Myanmar</option>
                <option value="NA">Namibia</option>
                <option value="NR">Nauru</option>
                <option value="NP">Nepal</option>
                <option value="NL">Netherlands</option>
                <option value="AN">Netherlands Antilles</option>
                <option value="NC">New Caledonia</option>
                <option value="NZ">New Zealand</option>
                <option value="NI">Nicaragua</option>
                <option value="NE">Niger</option>
                <option value="NG">Nigeria</option>
                <option value="NU">Niue</option>
                <option value="NF">Norfolk Island</option>
                <option value="MP">Northern Mariana Islands</option>
                <option value="NO">Norway</option>
                <option value="OM">Oman</option>
                <option value="PK">Pakistan</option>
                <option value="PW">Palau</option>
                <option value="PS">Palestinian Territory, Occupied</option>
                <option value="PA">Panama</option>
                <option value="PG">Papua New Guinea</option>
                <option value="PY">Paraguay</option>
                <option value="PE">Peru</option>
                <option value="PH">Philippines</option>
                <option value="PN">Pitcairn</option>
                <option value="PL">Poland</option>
                <option value="PT">Portugal</option>
                <option value="PR">Puerto Rico</option>
                <option value="QA">Qatar</option>
                <option value="RE">Reunion</option>
                <option value="RO">Romania</option>
                <option value="RU">Russian Federation</option>
                <option value="RW">Rwanda</option>
                <option value="BL">Saint Barthelemy</option>
                <option value="SH">Saint Helena</option>
                <option value="KN">Saint Kitts and Nevis</option>
                <option value="LC">Saint Lucia</option>
                <option value="MF">Saint Martin</option>
                <option value="PM">Saint Pierre and Miquelon</option>
                <option value="VC">Saint Vincent and the Grenadines</option>
                <option value="WS">Samoa</option>
                <option value="SM">San Marino</option>
                <option value="ST">Sao Tome and Principe</option>
                <option value="SA">Saudi Arabia</option>
                <option value="SN">Senegal</option>
                <option value="RS">Serbia</option>
                <option value="CS">Serbia and Montenegro</option>
                <option value="SC">Seychelles</option>
                <option value="SL">Sierra Leone</option>
                <option value="SG">Singapore</option>
                <option value="SX">Sint Maarten</option>
                <option value="SK">Slovakia</option>
                <option value="SI">Slovenia</option>
                <option value="SB">Solomon Islands</option>
                <option value="SO">Somalia</option>
                <option value="ZA">South Africa</option>
                <option value="GS">
                  South Georgia and the South Sandwich Islands
                </option>
                <option value="SS">South Sudan</option>
                <option value="ES">Spain</option>
                <option value="LK">Sri Lanka</option>
                <option value="SD">Sudan</option>
                <option value="SR">Suriname</option>
                <option value="SJ">Svalbard and Jan Mayen</option>
                <option value="SZ">Swaziland</option>
                <option value="SE">Sweden</option>
                <option value="CH">Switzerland</option>
                <option value="SY">Syrian Arab Republic</option>
                <option value="TW">Taiwan, Province of China</option>
                <option value="TJ">Tajikistan</option>
                <option value="TZ">Tanzania, United Republic of</option>
                <option value="TH">Thailand</option>
                <option value="TL">Timor-Leste</option>
                <option value="TG">Togo</option>
                <option value="TK">Tokelau</option>
                <option value="TO">Tonga</option>
                <option value="TT">Trinidad and Tobago</option>
                <option value="TN">Tunisia</option>
                <option value="TR">Turkey</option>
                <option value="TM">Turkmenistan</option>
                <option value="TC">Turks and Caicos Islands</option>
                <option value="TV">Tuvalu</option>
                <option value="UG">Uganda</option>
                <option value="UA">Ukraine</option>
                <option value="AE">United Arab Emirates</option>
                <option value="GB">United Kingdom</option>
                <option value="US">United States</option>
                <option value="UM">United States Minor Outlying Islands</option>
                <option value="UY">Uruguay</option>
                <option value="UZ">Uzbekistan</option>
                <option value="VU">Vanuatu</option>
                <option value="VE">Venezuela</option>
                <option value="VN">Viet Nam</option>
                <option value="VG">Virgin Islands, British</option>
                <option value="VI">Virgin Islands, U.s.</option>
                <option value="WF">Wallis and Futuna</option>
                <option value="EH">Western Sahara</option>
                <option value="YE">Yemen</option>
                <option value="ZM">Zambia</option>
                <option value="ZW">Zimbabwe</option>
              </select>
            </div>
            <div className="new-input personal_details_inputfields">
              <label>
                {" "}
                Year of Commencement{" "}
                <label style={{ color: "red", fontSize: "13px" }}>*</label>
              </label>
              <input
                onChange={onChangeEducationalDate}
                value={educationalData.Year_of_Commencement}
                name="Year_of_Commencement"
                type="date"
              ></input>
            </div>
            <div className="new-input personal_details_inputfields">
              <label>
                {" "}
                Year of Completion{" "}
                <label style={{ color: "red", fontSize: "13px" }}>*</label>
              </label>
              <input
                onChange={onChangeEducationalDate}
                value={educationalData.Year_of_Completion}
                name="Year_of_Completion"
                type="date"
              ></input>
            </div>
            <div className="educational_qualification_radiobtns">
              <input
                onChange={onChangeEducationalData}
                type="radio"
                name="Part_Full_Time"
                value={"Part Time"}
              />
              <label>Part-Time</label>
            </div>
            <div className="educational_qualification_radiobtns">
              <input
                onChange={onChangeEducationalData}
                type="radio"
                name="Part_Full_Time"
                value={"Full Time"}
              />
              <label>Full-Time </label>
            </div>
          </div>
          <div className="form-filed-area-1"></div>
          <button
            type="button"
            onClick={addEducationalData}
            className="add_data_btn"
          >
            <div>Save and Add New</div>
          </button>
        </div>
        <table id="educational_data">
          {educationalDataTable.data.length !== 0 ? (
            <tr>
              {/* <th>Level of Qualification</th> */}
              <th>Name of Qualification</th>
              <th>Name of Institute</th>
              <th>Country of Awarding</th>
              <th>Year of Commencement</th>
              <th>Year of Completion</th>
              <th>Part/Full Time</th>
            </tr>
          ) : (
            <></>
          )}
          {educationalDataTable.data.map((item, index) => {
            return (
              <tr key={index}>
                {/* <td>{item.Level_of_Qualification}</td> */}
                <td>{item.Name_of_Qualification}</td>
                <td>{item.Name_of_Institute}</td>
                <td>{item.Country_of_Awarding}</td>
                <td>{item.Year_of_Commencement}</td>
                <td>{item.Year_of_Completion}</td>
                <td>{item.Part_Full_Time}</td>
              </tr>
            );
          })}
        </table>
        <div className="employemnt-details">
          <h3 className="heading-form employment_details mb-5">
            EMPLOYMENT DETAILS
          </h3>
          <div className="new-area">
            <div className="new-input personal_details_inputfields">
              <label>
                Name of Company{" "}
                <label style={{ color: "red", fontSize: "13px" }}>*</label>
              </label>
              <input
                type="text"
                name="Name_of_Company"
                value={employemntData.Name_of_Company}
                onChange={OnchangeEmployemntDate}
                className="input-filed"
              />
            </div>
            <div className="new-input personal_details_inputfields">
              <label>
                Designation{" "}
                <label style={{ color: "red", fontSize: "13px" }}>*</label>
              </label>
              <input
                type="text"
                name="Destination"
                value={employemntData.Destination}
                onChange={OnchangeEmployemntData}
                className="input-filed"
              />
            </div>
            <div className="educational_qualification_radiobtns">
              <input
                onChange={OnchangeEmployemntDate}
                value={"Part Time"}
                type="radio"
                name="Part_Full_Time_Employment"
              />
              <label>Part-Time </label>
            </div>
            <div className="educational_qualification_radiobtns">
              <input
                type="radio"
                name="Part_Full_Time_Employment"
                value={"Full Time"}
                onChange={OnchangeEmployemntDate}
              />
              <label>Full-Time</label>
            </div>
            <div className="new-input personal_details_inputfields">
              <label>
                {" "}
                Year of Commencement{" "}
                <label style={{ color: "red", fontSize: "13px" }}>*</label>
              </label>
              <input
                type="date"
                name="Year_of_Commencement"
                value={employemntData.Year_of_Commencement}
                onChange={OnchangeEmployemntDate}
              ></input>
            </div>
            <div className="new-input personal_details_inputfields">
              <label> Year of Completion</label>
              <input
                type="date"
                name="Year_of_Completion"
                value={employemntData.Year_of_Completion}
                onChange={OnchangeEmployemntDate}
                disabled={
                  employemntData.Currently_Working === "Currently_Working"
                }
              ></input>
            </div>
            <div className="educational_qualification_radiobtns">
              <input
                type="checkbox"
                name="Currently_Working"
                onChange={OnchangeEmployemntDate}
              />
              <label>Currently Working</label>
            </div>
          </div>

          <div>
            <div className="employment_details_job_responsibilities mt-5">
              <label>
                {" "}
                Job Responsibilities{" "}
                <label style={{ color: "red", fontSize: "13px" }}>*</label>
              </label>
              <textarea
                onChange={OnchangeEmployemntDate}
                value={employemntData.Job_Responsibility}
                name="Job_Responsibility"
              ></textarea>
            </div>
          </div>
          <button
            onClick={addEmployemntData}
            type="button"
            className="add_data_btn"
          >
            <div>Save and Add New</div>
          </button>
        </div>
        <table id="educational_data" className="mt-5">
          {employemntDataTable.data.length !== 0 ? (
            <tr>
              {/* <th>Number of Employments</th> */}
              <th>Name of Company</th>
              <th>Designation</th>
              <th>Part/Full Time</th>
              <th>Year of Commencement</th>
              <th>Year of Completion</th>
              <th>Currently Working</th>
              <th>Job Responsibilities</th>
            </tr>
          ) : (
            <></>
          )}

          {employemntDataTable.data.map((item, index) => {
            return (
              <tr key={index}>
                {/* <td>{item.Number_of_Employments}</td> */}
                <td>{item.Name_of_Company}</td>
                <td>{item.Destination}</td>
                <td>{item.Part_Full_Time_Employment}</td>
                <td>{item.Year_of_Commencement}</td>
                <td>{item.Year_of_Completion}</td>
                <td>{item.Currently_Working}</td>
                <td>{item.Job_Responsibility}</td>
              </tr>
            );
          })}
        </table>
        <div className="button-container">
          <button
            onClick={() => navigate(-1)} // Use navigate with -1 to go back
            className="BackBtn"
            type="button"
          >
            Back
          </button>
          <button
            onClick={submitFormData}
            className="FormSubmitBtn"
            type="button"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormFiled;
