import axios from "axios";
import React, { useState } from "react";
import like from "../../assets/images/like.svg";
import dislike from "../../assets/images/dislike.svg";
import Popup from "../feedback/Popup";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useStatus } from "../../statusContext";
import { Oval } from "react-loader-spinner";
import FileViewer from "react-file-viewer";
import { Button, Table } from "semantic-ui-react";
import "../../css/index.css";
import "../../css/lexx.css";

function SubmitResult() {
  const location = useLocation();
  const file = sessionStorage.getItem("uploadedFile");
  let type =
    sessionStorage.getItem("type") === "application/pdf" ? "pdf" : "docx";
  const onError = (e) => {
    console.log(e, "error in file-viewer");
  };

  const navigate = useNavigate();
  const navigateToForm = () => {
    if (location.state && location.state.applicant_id) {
      navigate("/formfiledapplicent", {
        state: { applicant_id: location.state.applicant_id },
      });
    } else {
      alert("Form already submitted.");
    }
  };
  const handlenext = () => {
    if (status === "Not a Resume") {
      navigate("/");
    } else if (!rating.profession) {
      alert("Please give Thumbs up to the most Suitable Profession.");
    } else if (rating.profession === "dislike") {
      navigate("/");
    } else {
      navigate("/");
    }
  };
  const [showpopup, setshowpopup] = useState("hide");
  const [rating, setRating] = useState({
    profession: null,
    thumbsDown: false, // Initial thumbsDown state
  });

  const sendFeedback = async (profession, thumbsUp) => {
    try {
      const response = await axios.post(
        `https://api.xhorizons-uat.lexxtechnologies.com/feedback/${location.state.applicant_id}`,
        {
          feedbacks: [
            {
              occupation: profession,
              thumbsupdown: thumbsUp,
              // comment: feedback.feedback,
            },
          ],
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };

  const { status, result, docID } = useStatus();

  const convertAndSort = (jobData) => {
    const jobsArray = Object.entries(jobData).map(([jobTitle, jobInfo]) => {
      const [start, end] = jobInfo.period.split(", ");
      let [startMonth, startYear] = start.split("/");
      let startDate = new Date(startYear, startMonth - 1);
      let formatedStartDate = startDate.toLocaleDateString("default", {
        year: "numeric",
        month: "long",
      });
      let endDate;
      let formatedEndDate;
      if (end === "-") {
        endDate = formatedEndDate = "Now";
      } else {
        let [endMonth, endYear] = end.split("/");
        endDate = new Date(endYear, endMonth - 1);
        formatedEndDate = endDate.toLocaleDateString("default", {
          year: "numeric",
          month: "long",
        });
      }
      return {
        jobTitle: jobInfo.job,
        startDate,
        formatedStartDate,
        endDate,
        formatedEndDate,
        country: jobInfo.country,
      };
    });
    return jobsArray.sort((a, b) => a.startDate - b.startDate);
  };

  return (
    <>
      <div className={`${showpopup === "hide" ? "pophiden" : "popposition"} `}>
        <Popup
          setshowpopup={setshowpopup}
          applicent_id={docID}
          rating={rating}
        />
      </div>
      <div className="result-content">
        <div className="table-result">
          <h2 className="status">{status}</h2>
          <h2 className="pathway">Recommended Pathway : {result?.stream}</h2>
          {result?.job ? (
            <Table
              celled
              unstackable
              style={{ border: "none" }}
              className="table-wrapper"
            >
              <tr
                style={{
                  position: "sticky",
                  top: "0",
                  zIndex: "1",
                  backgroundColor: "#0971CE",
                  color: "white",
                }}
              >
                <th>Occupation</th>
                <th>Country Name</th>
                <th>Start date</th>
                <th>End date</th>
              </tr>

              {convertAndSort(result?.job).map((job) => (
                <tr key={job.jobTitle}>
                  <td>{job.jobTitle}</td>
                  <td>{job.country}</td>
                  <td>
                    {job.startDate instanceof Date
                      ? job.startDate.toLocaleDateString()
                      : job.startDate}
                  </td>
                  <td>
                    {job.endDate instanceof Date
                      ? job.endDate.toLocaleDateString()
                      : job.endDate}
                  </td>
                </tr>
              ))}
            </Table>
          ) : (
            <div style={{ marginLeft: "200px" }}>
              <Oval
                height={50}
                width={50}
                color="#0971ce"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="gray"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          )}
          <h2 className="professions ">Top 5 Professions</h2>
          <Table
            celled
            unstackable
            style={{ border: "none" }}
            className="table-wrapper"
          >
            <tbody>
              {result ? (
                result["top_professions"].map((profession, index) => (
                  <tr key={index}>
                    <td>{profession}</td>
                    <td>
                      <img
                        src={like}
                        alt="like"
                        className={`thumbs-up ${
                          rating.profession === profession && !rating.thumbsDown
                            ? "thumbs-up-selected"
                            : ""
                        }`}
                        onClick={() => {
                          sendFeedback(profession, true);
                          setshowpopup("show");
                          setRating({
                            profession: profession,
                            thumbsDown: false, // Ensure thumbsDown is set to false when thumbs-up is selected
                          });
                        }}
                      ></img>
                    </td>

                    <td>
                      <img
                        src={dislike}
                        alt="dislike"
                        className={`thumbs-down ${
                          rating.profession === profession && rating.thumbsDown
                            ? "thumbs-down-selected"
                            : ""
                        }`}
                        onClick={() => {
                          sendFeedback(profession, false);
                          setshowpopup("show");
                          setRating({
                            profession: profession,
                            thumbsDown: true, // Set thumbsDown to true when thumbs-down is selected
                          });
                        }}
                      ></img>
                    </td>
                  </tr>
                ))
              ) : (
                <div style={{ marginLeft: "200px" }}>
                  <Oval
                    height={50}
                    width={50}
                    color="#0971ce"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="gray"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </div>
              )}
            </tbody>
          </Table>
        </div>
        <div className="table-result-viewer">
          <FileViewer fileType={type} filePath={file} onError={onError} />
        </div>
      </div>

      <div className="button-previous">
        <Button
          className="button-1"
          onClick={navigateToForm}
          disabled={status !== "Not a Resume"}
        >
          <span>Profile Info</span>
        </Button>
        <Link to={"/uploadfile"}>
          <Button className="button-1" disabled={result}>
            Back
          </Button>
        </Link>
        <Button className="button-1" onClick={handlenext}>
          <span>Next</span>
        </Button>
      </div>
    </>
  );
}

export default SubmitResult;
