/***************************
 * FormField: This component show the from and api integartion.
 * Contributors: Abhishek Kuamr Yadav
 ***************************/

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./../../css/formField.css";
import { SyncLoader } from "react-spinners";
import { Link } from "react-router-dom";
const FormFiled = (props) => {
  const [isloading, setLoading] = useState(false);
  const navigate = useNavigate();
  // Employment Detail table data
  const [personaldetail, setPersonalDetail] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    DateOfBirth: "",
    Nationality: "",
  });

  // Employment Detail current data updating function
  const onchangePersonalemail = (e) => {
    setPersonalDetail({ ...personaldetail, [e.target.name]: e.target.value });
  };
  const onchangePersonalFirstName = (e) => {
    const { name, value } = e.target;
    // Check if the input is a number
    const isNumber = !isNaN(parseFloat(value)) && isFinite(value);
  
    // If it's a number, directly add it to personaldetail
    if (isNumber) {
      setPersonalDetail({ ...personaldetail, [name]: value });
    } else {
      // If it's not a number, convert the first letter of each word to proper case
      const words = value.split(' ');
      const properCaseValue = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      setPersonalDetail({ ...personaldetail, [name]: properCaseValue });
    }
  };
  
  
  const onchangePersonalData = (e) => {
    const { name, value } = e.target;
    const alphaRegex = /^[a-zA-Z]*$/; // Regular expression to match only alphabetic characters
    if (!alphaRegex.test(value)) {
      // If the input value contains non-alphabetic characters, do not update the state
      return;
    }
    setPersonalDetail({
      ...personaldetail,
      [name]: value.trim().replace(/\b\w/g, (c) => c.toUpperCase()),
    });
  };
  
  const onchangePersonalDate = (e) => {
    const { name, value } = e.target;
    setPersonalDetail({
      ...personaldetail,
      [name]: value.trim().replace(/\b\w/g, (c) => c.toUpperCase()),
    });
  };
  const submitFormData = async () => {
    const today = new Date();
    const dob = new Date(personaldetail.DateOfBirth);
    const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
    
    if (dob > eighteenYearsAgo) {
      alert("The Applicant does not qualify the minimum age requirements..");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(personaldetail.Email)) {
      alert("Please provide a valid email.");
      return;
    } else if (
      personaldetail.DateOfBirth === "" ||
      personaldetail.Email === "" ||
      // personaldetail.FirstName === "" ||
      personaldetail.LastName === "" ||
      personaldetail.Nationality === ""
    ) {
      alert("Please fill up the mandatory information!");
      return;
    }
    if (!document.getElementById("check").checked) {
      alert("Please give your consent to use your personal information!");
      return;
    }
    const ageDiffMs = Date.now() - dob.getTime();
    const ageDate = new Date(ageDiffMs); // milliseconds from epoch
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);

    if (age > 45) {
      alert("Not eligible for Immigration. Age greater than 45 years.");
      return;
    }

    let data = {
      FirstName: personaldetail.FirstName,
      LastName: personaldetail.LastName,
      Email: personaldetail.Email,
      DateOfBirth: personaldetail.DateOfBirth,
      Nationality: personaldetail.Nationality,
    };
    setLoading(true);
    const url = " https://api.xhorizons-uat.lexxtechnologies.com/form";
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const json = await response.json();
      setLoading(false);
      if (json.applicant_id) {
        navigate("/uploadfile", { state: { applicant_id: json.applicant_id } });
      } else if (json.detail) {
        alert(json.detail);
      } else {
        alert("An error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again.");
      setLoading(false);
    }
};


  return (
    <>
      <center>
        {" "}
        <SyncLoader
          className={`${!isloading ? "hidden" : "midd"}`}
          color="#0971ce"
        />{" "}
      </center>
      <div className={`${isloading ? "hidden" : "form_body"}`}>
        <div className="h-context">
          <h3 className="pt-5 form_heading">APPLICANT ENTRY FORM</h3>
          <ul>
            <li style={{ color: "red" }}>
            Please note that fields marked with an asterisk (*) are mandatory.
            </li>
          </ul>
        </div>
        <form className="pb-10">
          <div>
            <h3 className="heading-form personal_details mb-5">
              PERSONAL DETAILS
            </h3>
            <div className="filed-area">
              <div className="first-name personal_details_inputfields">
                <label>
                  First Name{" "}
                  {/* <label style={{ color: "red", fontSize: "13px" }}>*</label> */}
                </label>
                <input
                  onChange={ onchangePersonalFirstName }
                  value={personaldetail.FirstName}
                  type="text"
                  name="FirstName"
                />
              </div>
              <div className="last-name personal_details_inputfields">
                <label>
                  Last Name{" "}
                  <label style={{ color: "red", fontSize: "13px" }}>*</label>{" "}
                </label>
                <input
                  onChange={onchangePersonalFirstName }
                  value={personaldetail.LastName}
                  type="text"
                  name="LastName"
                />
              </div>
              <div className="personal_details_inputfields">
                <label>
                  {" "}
                  Date of Birth{" "}
                  <label style={{ color: "red", fontSize: "13px" }}>*</label>
                </label>
                <input
                  onChange={onchangePersonalDate}
                  value={personaldetail.DateOfBirth}
                  name="DateOfBirth"
                  type="date"
                  data-date=""
                  data-date-format="DD MMMM YYYY"
                ></input>
              </div>
            </div>
            <div className="form-filed-area-1">
              <div className="personal_details_inputfields">
                <label>
                  {" "}
                  Nationality{" "}
                  <label style={{ color: "red", fontSize: "13px" }}>*</label>
                </label>
                <input
                  onChange={onchangePersonalData}
                  value={personaldetail.Nationality}
                  type="text"
                  name="Nationality"
                />
              </div>
              <div className="personal_details_inputfields">
                <label>
                  {" "}
                  Email ID{" "}
                  <label style={{ color: "red", fontSize: "13px" }}>*</label>
                </label>
                <input
                  onChange={onchangePersonalemail}
                  value={personaldetail.Email}
                  type="email"
                  name="Email"
                />
              </div>
              <div className="personal_details_inputfields"></div>
            </div>
            <div className="checkbox-consinment">
              <input
                type="checkbox"
                id="check"
                style={{ marginRight: "10px" }}
              />
              <label>
                I agree to use of my personal details by eMigration Advisor for
                ACS Immigration process.
              </label>
            </div>
          </div>
          <div className="button-previous-form">
          <Link to={"/"}>
          <button
            className="FormSubmitbt"
            type="button"
          >
            Back
          </button>
          </Link>
          <button
            onClick={() => {
              submitFormData();
            }}
            className="FormSubmitBtn"
            type="button"
          >
            Submit
          </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default FormFiled;
