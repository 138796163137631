/*******************************************************************************
* Email sent :After the createlist when click on submit then show the email sent 
  pop-up 
* Contributors: Abhishek Kuamr Yadav
*******************************************************************************/
import React from "react";
import { Link } from "react-router-dom";
//simple popup component after send to mail
const Emailsent = (props) => {
  return (
    <div className="emailsentpopup">
      <div className="emailsentpop">
        <div className="emailpopmessage">
          <h1>
            An Email has been sent to
            <h1 className="h1-text">the Applicant</h1>
          </h1>
        </div>
        <Link to={"/"}>
          <div>
            <button className="okay-popup">Ok</button>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Emailsent;
